import getConfig from 'next/config';
import { getPathName } from '@/lib/helpers/getPathName';

export const getRootClientLink = (
  path: string,
): string => {
  const { publicRuntimeConfig = {} } = getConfig() || {};
  const { API_HOST_PUBLIC } = publicRuntimeConfig;

  const pathName = getPathName(path);

  return `https://${API_HOST_PUBLIC}${pathName}`;
};
