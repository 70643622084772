import { SelectOptionInterface } from '@/controllers/forms/forms.typedefs';
import {
  ProgrammingLanguage,
  TestTaskBinaryFile,
  TestTaskConfigWithInheritance,
  TestTaskCreationStatus,
  TestTaskFile,
  TestTaskFileKind,
  TestTaskFileType,
  TestTaskFlow,
  TestTaskVersionCreationStatus,
} from '@/controllers/graphql/generated';
import { CodeEditorFile } from '../../CodeEditor/CodeEditorFile';
import { TaskDescription } from '../LmsEditor.typedefs';

export enum CodeEditorFlowTaskFormSteps {
  BaseTaskInfo = 1,
  Description = 2,
  Config = 3,
  Files = 4,
}

export type CodeEditorLanguage = Extract<
  ProgrammingLanguage,
  ProgrammingLanguage.Java
  | ProgrammingLanguage.Javascript
  | ProgrammingLanguage.Python
  | ProgrammingLanguage.Sql
  | ProgrammingLanguage.Typescript
  | ProgrammingLanguage.Markup
>;

export interface CodeEditorTaskFormFields {
  id?: number;
  name: string;
  programmingLanguage: SelectOptionInterface<CodeEditorLanguage>;
  taskFileName: string;
  isFileTreeVisible: SelectOptionInterface<boolean>;
  isLinterBlocker: SelectOptionInterface<boolean>;
  isLinterEnabled: SelectOptionInterface<boolean>;
  isRefSolutionVisible: SelectOptionInterface<boolean>;
  isSampleTestsVisible: SelectOptionInterface<boolean>;
  isAutocompleteEnabled: SelectOptionInterface<boolean>;
  notRequiresReview: SelectOptionInterface<boolean>;
  tests: SelectOptionInterface<boolean>;
  testType?: SelectOptionInterface<string>;
  async?: SelectOptionInterface<boolean>;
  estimatedTimeToSolve: SelectOptionInterface<number>;
  shouldShowReportProblem: SelectOptionInterface<boolean>;
  daysToSolve: string;
  referenceSolution: string;
  externalReferenceSolution: string;
}

export enum CodeEditorFilesNames {
  Reference = 'reference',
  Template = 'template',
  Test = 'test',
  Index = 'index',
  Main = 'main',
  Style = 'style',
}

type TestTaskFileTypename = Pick<TestTaskFile, '__typename'> |
  Pick<TestTaskBinaryFile, '__typename'>;

export type LmsCodeEditorFile = CodeEditorFile
  & TestTaskFileTypename
  & {
    pathToFile: string;
    isReadonlyForStudents: boolean;
    fileType: TestTaskFileType;
    kind: TestTaskFileKind;
    isLoading: boolean;
  };

export type LmsCodeEditorFileMap = Record<string, LmsCodeEditorFile>;

export interface CodeEditorTaskVersion {
  id: number;
  versionNumber: number;
  descriptionFiles: TaskDescription;
  taskFiles: LmsCodeEditorFileMap;
  creationStatus: TestTaskVersionCreationStatus;
}

export interface CodeEditorFlowTestTaskInterface {
  formValues: Partial<CodeEditorTaskFormFields>;
  versions: CodeEditorTaskVersion[];
  config: TestTaskConfigWithInheritance;
  creationStatus: TestTaskCreationStatus;
}

export type EditorTypeFlow = TestTaskFlow.CodeEditor
  | TestTaskFlow.Markup
  | TestTaskFlow.Web;
