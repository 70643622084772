import differenceBy from 'lodash/differenceBy';
import isNull from 'lodash/isNull';
import { Identifiable, Truthy } from '@/typedefs/common';
import { MinimumArray, NotEmptyArr } from '@/lib/helpers/utility-types';

interface Clamp {
  (val: number, min: number, max: number): number;
}

type PluckResult<T, K extends keyof T = keyof T> = (
  T extends null
    ? null
    : T[K]
);

export const id = <T>(x: T): T => x;

export const truthy = <T>(value: T): value is Truthy<T> => !!value;

export const emptyFunction = () => { /* EMPTY */ };

export const nullFunction = () => null;

export const isStringEmpty = (value: string) => (
  value.trim().length === 0
);

export const isArrayEmpty = (array: any[]) => (
  array.length === 0
);

export const isNotEmptyArray = <T>(array: T[]): array is NotEmptyArr<T> => (
  array.length > 0
);

export const arrayHasLengthEqualOrMore = <T, Length extends number>(
  array: T[],
  length: Length,
): array is MinimumArray<Length, T> => array.length >= length;

export const makeThrottledDebounce = <CB extends (...args: any[]) => any>(
  throttleTime: number,
) => (
    cb: CB,
  ): (...args: Parameters<CB>
) => void => {
    let lastTime = 0;
    let timerId: ReturnType<typeof setTimeout> | null = null;

    return (...args: Parameters<CB>) => {
      const currentTime = Date.now();
      const delta = currentTime - lastTime;

      if (delta >= throttleTime) {
        lastTime = currentTime;

        if (timerId !== null) {
          clearTimeout(timerId);
          timerId = null;
        }

        cb(...args);

        return;
      }

      timerId = setTimeout(() => {
        timerId = null;

        cb(...args);
      }, throttleTime - delta);
    };
  };

export const clamp: Clamp = (val, min, max) => (
  Math.max(min, Math.min(val, max))
);

export const uniquifyById = <T extends Identifiable>(
  incoming: T[],
  existing: T[],
): T[] => ([
    ...existing,
    ...differenceBy(incoming, existing, (element) => (
      element.id
    )),
  ]);

export const excludeById = <T extends Identifiable>(
  source: T[],
  excludable: T[],
): T[] => (
    differenceBy(source, excludable, (element) => (
      element.id
    ))
  );

export const pluck = <T, K extends keyof T = keyof T>(key: K) => (
  (obj: T): PluckResult<T, K> => {
    if (isNull(obj)) {
      return null as PluckResult<T, K>;
    }

    return obj[key] as PluckResult<T, K>;
  }
);

export const arrangeIf = <T = any>(condition: boolean, ...items: T[]): T[] => (
  condition
    ? items
    : []
);

/**
 * Checks if the given value is not null or undefined.
 * @template T The type of the value to return if it exists.
 * @param {T | null | undefined} value The value to check.
 * @returns {boolean} True if the value is not null or undefined, false otherwise. Resolves value type to T if it exists, serves as a type guard.
 */
export function exists<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null;
}

export const isValueInObject = (
  object: Record<string | number, unknown>,
) => (
  value: unknown,
): boolean => (
  Object.values(object).includes(value)
);

export const subtractWithFloatingPoint = (
  minuend: number,
  subtrahend: number,
  correctionFactor = 100,
): number => (
  Math.round((minuend - subtrahend) * correctionFactor) / correctionFactor
);

export const getRandomBoolean = (): boolean => (
  Math.random() >= 0.5
);
