import React from 'react';
import { DEFAULT_SUB_DOMAIN } from '@/middleware/i18n/i18n.config';
import { SubDomains } from '@/constants/subdomains';

export const SubDomainContext = React.createContext<{
  subDomain: SubDomains | string;
}>({
  subDomain: DEFAULT_SUB_DOMAIN,
});

export const SubDomainProvider = SubDomainContext.Provider;
