import '@/style/scss/main.scss';
import App, { AppContext, AppProps } from 'next/app';
import React, { FC } from 'react';
import { DIProvider } from '@mate-academy/react-di';
import { LogLevels } from '@mate-academy/logger';
import { withFeaturesApp } from '@/controllers/features/withFeatureApp';
import { compose } from '@/lib/compose';
import { appWithTranslation, i18n } from '@/middleware/i18n';
import { withApolloApp } from '@/controllers/apollo/apollo.hocs/withApolloApp';
import { logger } from '@/core/Logger/Logger';
import { initAmplitude } from '@/controllers/amplitude/amplitude.helpers';
import { DefaultSeoTags } from '@/seo/DefaultSeoTags';
import { Confirm } from '@/components/common/Confirm';
import { ReadyApolloClient } from '@/controllers/apollo/apollo.typedefs';
import { LayoutOptions } from '@/typedefs/pageProps';
import { providers } from '@/providers';
import { GoogleAnalyticsClient } from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';
import { errorHandler } from '@/core/ErrorHandler';
import { withSubDomainApp } from '@/controllers/subDomain/withSubDomainApp';
import { DomainRedirectHandler } from '@/components/common/DomainRedirectHandler';
import {
  ScreenHeightCalculator,
} from '@/components/common/ScreenHeightCalculator';
import { initProgressBar } from '@/core/progressBar';
import { withGrowthBookApp } from '@/components/services/GrowthBook/GrowthBook.hocs/withGrowthBookApp';
import {
  GrowthBookClient,
} from '@/components/services/GrowthBook/GrowthBook.client';
import { PlatformWideSubscriptions } from '@/components/common/PlatformWideSubscriptions';
import { FeaturesContextProvider } from '@/components/common/FeaturesContext';
import { RefetchActiveQueriesOnOnline } from '@/components/platform/Chat/components/RefetchActiveQueriesOnOnline';
import { EMPTY_OBJECT } from '@/constants';
import { AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';
import {
  AuthAdminWithAuthUserDocument,
  AuthAdminWithAuthUserQuery,
} from '@/controllers/user/graphql/generated/authAdminWithAuthUser.query.generated';
import { AuthUserDocument, AuthUserQuery } from '@/controllers/user/graphql/generated/authUser.query.generated';

initProgressBar();

export interface MateAppProps extends AppProps {
  apolloClient?: ReadyApolloClient;
  currentLanguage?: string;
  pagePath?: string;
  subDomain?: string;
  Component: AppProps['Component'] & {
    Layout?: FC<{ layoutOptions: LayoutOptions }>;
    layoutOptions?: LayoutOptions;
  };
}

export interface AppGetInitialProps {
  (ctx: AppContext): Promise<any>;
}

class MateApp extends App<MateAppProps> {
  static async getInitialProps({ Component, ctx }: any) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx) ?? {};
    }

    const { req } = ctx;
    const path = req?.originalUrl || null;

    const currentLanguage = req
      ? req.language
      : i18n.language;

    return {
      pageProps,
      currentLanguage,
      pagePath: path,
      subDomain: ctx.subDomain,
    };
  }

  async componentDidMount() {
    const { apolloClient, subDomain } = this.props;

    let authUser: AuthUserFragment | null = null;

    if (apolloClient) {
      try {
        const { data: authAdminData } = await apolloClient.query<
          AuthAdminWithAuthUserQuery
        >({
          query: AuthAdminWithAuthUserDocument,
        });

        if (authAdminData.adminUser?.id) {
          authUser = authAdminData.adminUser;
        } else {
          const { data } = await apolloClient.query<AuthUserQuery>({
            query: AuthUserDocument,
          });

          authUser = data.authUser ?? null;
        }
      } catch (e) {
        // do nothing
      }
    }

    GoogleAnalyticsClient.getInstance().setUser(authUser);

    errorHandler.setUser(authUser);

    logger.setUserId(authUser?.id ?? null);
    // TODO: move amplitude to internal analytics
    initAmplitude(authUser || null, {
      subDomain,
      deviceId: GoogleAnalyticsClient.getInstance().getAnalyticsDeviceId(),
    });

    // not passing auth user because it takes values from google analytics client
    GrowthBookClient.getInstance().updateUserAttributes();
    GrowthBookClient.getInstance().init();
  }

  componentDidCatch(error: Error) {
    if (error.message.includes('not a child of this node')) {
      // do nothing
      return;
    }

    errorHandler.captureException(
      error,
      {
        logMessage: 'COMPONENT CRASHED',
        logLevel: LogLevels.Error,
      },
    );
  }

  render() {
    const {
      Component,
      pageProps,
      pagePath,
    } = this.props;

    if (!Component.Layout) {
      // if component doesn't have a layout then user will see a blank page anyway
      // so error throwing here make a problem more visible and doesn't affect UX
      // eslint-disable-next-line @mate-academy/frontend/restrict-error-throwing
      throw new Error(`Page ${pagePath} must have Layout static field`);
    }

    return (
      <FeaturesContextProvider>
        <DIProvider providers={providers}>
          <DomainRedirectHandler />
          <ScreenHeightCalculator />
          <DefaultSeoTags />
          <PlatformWideSubscriptions />
          <RefetchActiveQueriesOnOnline />
          <Component.Layout
            layoutOptions={Component.layoutOptions ?? EMPTY_OBJECT}
          >
            <Component {...pageProps} />

            <Confirm />
          </Component.Layout>
        </DIProvider>
      </FeaturesContextProvider>
    );
  }
}

export default compose(
  withSubDomainApp,
  withApolloApp,
  withGrowthBookApp,
  withFeaturesApp,
  appWithTranslation,
)(MateApp);
