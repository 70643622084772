import { useContext } from 'react';
import { SubDomainContext } from '@/controllers/subDomain/subDomain.context';
import { useTranslation } from '@/middleware/i18n';
import { i18nParseLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import { isSubscriptionProductDomain } from '@/controllers/subDomain/subDomain.utils/isSubscriptionProductDomain';

export const useSubDomainContext = () => {
  const contextData = useContext(SubDomainContext);

  const { i18n } = useTranslation();

  return {
    ...contextData,
    language: i18nParseLanguageSubpath(i18n.language).language,
    isSubscriptionProductDomain: isSubscriptionProductDomain(
      contextData.subDomain,
    ),
  };
};
