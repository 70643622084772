import { PlatformTheme } from '@/components/common/ThemeContext/typedefs';
import {
  FAIL_PAYMENT_STATUSES,
  TEMPORARY_EMAIL_PATTERN,
} from '@/components/platform/SubscriptionProduct/constants';
import { SubscriptionProductSubscriptionStatus } from '@/components/platform/SubscriptionProduct/typedefs';
import {
  PaymentBaseFragment,
  SubscriptionFragment,
  SubscriptionPeriod,
  SubscriptionTrialPeriod,
  TransactionTypes,
} from '@/controllers/graphql/generated';
import { isSubscriptionProductDomain } from '@/controllers/subDomain/subDomain.utils/isSubscriptionProductDomain';
import { DEFAULT_SUB_DOMAIN } from '@/middleware/i18n/i18n.config';
import { AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';
import { PaymentTransactionMinimalFragment } from '@/components/platform/SubscriptionProduct/components/Subscription/components/BillingHistory/graphql/generated/paymentTransactionMinimal.fragment.generated';
import { BadgePaymentStatus } from '@/components/platform/Payment/TransactionStatusBadge/typography';

export const getCopmanyLogoColor = (
  color: string,
  theme: PlatformTheme,
  override = false,
) => (
  theme === PlatformTheme.Dark || override
    ? '#fff'
    : color
);

export const getPriceDetails = (
  price: number,
  period: SubscriptionPeriod | SubscriptionTrialPeriod,
  periodDuration: number,
) => (
  (periodDuration ?? 0) > 1
    ? `$${price} per ${periodDuration} ${period}s`
    : `$${price} per ${period}`
);

export const getSubscriptionStatus = (
  subscription?: SubscriptionFragment | null,
  lastPayment?: PaymentBaseFragment | null,
): SubscriptionProductSubscriptionStatus => {
  /*
    subscription.active - indicates if we will try to charge the user
    subscription.dateNext - date of next charge
    subscription.expired - manages user's access to course
  */

  const freePlanOrFreeTrial = !subscription
    || (!lastPayment && subscription.active);

  if (freePlanOrFreeTrial) {
    return SubscriptionProductSubscriptionStatus.Active;
  }

  const now = new Date();

  const gracePeriod = !subscription.expired
    && subscription.active
    && subscription.dateNext > now
    && lastPayment?.status
    && FAIL_PAYMENT_STATUSES.includes(lastPayment.status);

  const isBlockedAccessAndWillRetryLater = subscription.expired
    && subscription.active
    && subscription.dateNext > now
    && lastPayment?.status
    && FAIL_PAYMENT_STATUSES.includes(lastPayment.status);

  if (gracePeriod || isBlockedAccessAndWillRetryLater) {
    return SubscriptionProductSubscriptionStatus.Pending;
  }

  const isCancelledByUserAndRenewalStopped = !subscription.expired
    && !subscription.active
    && subscription.dateNext > now;

  const isBlockedAccessAndRenewalStopped = subscription.expired
    && !subscription.active;

  if (
    isCancelledByUserAndRenewalStopped
    || isBlockedAccessAndRenewalStopped
  ) {
    return SubscriptionProductSubscriptionStatus.Canceled;
  }

  return SubscriptionProductSubscriptionStatus.Active;
};

export const getIsTemporaryEmailUser = (email?: string) => {
  const regexp = new RegExp(TEMPORARY_EMAIL_PATTERN);

  return (
    email
    && regexp.test(email)
  );
};

export const getIsPageBlocked = (authUser?: AuthUserFragment | null) => {
  const isTemporaryEmailUser = getIsTemporaryEmailUser(authUser?.email);
  const isSubscriptionProduct = isSubscriptionProductDomain(
    authUser?.domain?.slug ?? DEFAULT_SUB_DOMAIN,
  );

  return isTemporaryEmailUser && isSubscriptionProduct;
};

export const getBillingHistoryPaymentStatus = (
  payment: PaymentTransactionMinimalFragment,
): BadgePaymentStatus => {
  const isRefund = (
    Boolean(payment.refundDate)
    || payment.transactionType === TransactionTypes.Auth
  );

  if (isRefund) {
    return 'refunded';
  }

  return payment.status ?? 'skipped';
};
