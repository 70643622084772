const BackendAdapter = require('i18next-multiload-backend-adapter');

const getConfig = require('next/config').default;
const NextI18Next = require('next-i18next').default;
const HttpApi = require('i18next-http-backend');
const { getApiLink } = require('../../lib/helpers/getApiLink');
const { DEFAULT_LOCALE, FALLBACK_LOCALE } = require('./i18n.config');
const { domainLocaleDetector } = require('./i18n.domainLocaleDetector');

const apiPath = getApiLink('translates');

const { publicRuntimeConfig = {} } = getConfig() || {};

const {
  TRANSLATES_LOADING_MODE,
  NODE_ENV,
  DYNAMIC_I18N_CONFIG,
} = publicRuntimeConfig;

const allowMultiLoading = TRANSLATES_LOADING_MODE === 'MULTIPLE';

const NextI18NextInstance = new NextI18Next({
  fallbackLng: FALLBACK_LOCALE,
  defaultNS: 'all',
  defaultLanguage: DEFAULT_LOCALE,
  otherLanguages: (DYNAMIC_I18N_CONFIG?.languages || []),
  keySeparator: false,
  appendNamespaceToCIMode: true,
  appendNamespaceToMissingKey: false,
  debug: false,
  saveMissing: NODE_ENV !== 'production',
  lowerCaseLng: true,
  supportedLngs: (DYNAMIC_I18N_CONFIG?.languages || []),
  backend: allowMultiLoading
    ? {
      backend: HttpApi,
      backendOption: {
        loadPath: `${apiPath}/multiple?lang={{lng}}&namespace={{ns}}`,
        addPath: `${apiPath}?lang={{lng}}&namespace={{ns}}`,
        allowMultiLoading: true,
      },
    }
    : {
      loadPath: `${apiPath}?lang={{lng}}&namespace={{ns}}`,
      addPath: `${apiPath}?lang={{lng}}&namespace={{ns}}`,
    },
  use: [
    allowMultiLoading
      ? BackendAdapter
      : HttpApi,
  ],
  // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
  localePath: typeof window === 'undefined'
    ? 'public/static/locales'
    : 'static/locales',
  browserLanguageDetection: true,
  serverLanguageDetection: true,
  customDetectors: [domainLocaleDetector],
  detection: {
    order: ['domain'],
  },

});

module.exports = NextI18NextInstance;
