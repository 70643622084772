const getConfig = require('next/config').default;
const { isBrowser } = require('./isBrowser');

const getApiLink = (path, host) => {
  const { publicRuntimeConfig = {} } = getConfig() || {};
  const {
    API_SSL, API_PORT, API_HOST, API_HOST_PUBLIC, API_PATH,
  } = publicRuntimeConfig;

  const config = {
    ssl: API_SSL === 'true',
    port: API_PORT,
    hostname: host || (
      isBrowser
        ? API_HOST_PUBLIC
        : API_HOST
    ),
  };

  const {
    ssl, hostname, port,
  } = config;

  const protocol = `http${(ssl && isBrowser)
    ? 's'
    : ''}`;
  const apiPort = (ssl && isBrowser)
    ? 443
    : port;

  return `${protocol}://${hostname}:${apiPort}/${path || API_PATH}`;
};

module.exports = { getApiLink };
