import { sendAmplitudeData } from '@/controllers/amplitude/amplitude.helpers';
import { GoogleAnalyticsClient } from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';

export const ChatEvents = {
  sendEvent: (event: string, data?: Record<string, any>) => {
    const eventName = `chat_${event}`;

    sendAmplitudeData(eventName, data);
    GoogleAnalyticsClient.getInstance().sendEvent(eventName, data);
  },
  events: {
    openQuestionAnswered: 'open_question_answered',
    pollAnswered: 'poll_answered',
    openQuestionAnswerRated: 'open_question_answer_rated',
    guidelineMessageSentToChat: 'guideline_message_sent_to_chat',
    wrapCodeUsed: 'wrap_code_used',
    emojiUsed: 'emoji_used',
    sortedByCorrectness: 'sorted_by_correctness,',
    selectorTabChanged: 'selector_tab_changed',
    participantJoined: 'participant_joined',
    participantLeft: 'participant_left',
    participantKicked: 'participant_kicked',
    mediaFileAttached: 'media_file_attached',
    mediaFileRemoved: 'media_file_removed',
    mediaFileUploadFailed: 'media_file_upload_failed',
    mediaFilePreviewOpened: 'media_file_preview_opened',
    mentionButtonUsed: 'mention_button_used',
    mentionAdded: 'mention_added',
    descriptionMentionAdded: 'description_mention_added',
    editProfileButtonClicked: 'edit_profile_button_clicked',
    participantAvatarUpdated: 'participant_avatar_updated',
    sandboxChatQuestionAnswered: 'sandbox_chat_question_answered',
    threadsTabOpened: 'threads_tab_opened',
    savedMessagesTabOpened: 'saved_messages_tab_opened',
    draftSaved: 'draft_saved',
    draftRecoveredAndSent: 'draft_recovered_and_sent',
    platformLevelSettingsUpdated: 'platform_level_settings_updated',
    chatLevelSettingsUpdated: 'chat_level_settings_updated',
    notificationsDisabledModalOpened: 'notifications_disabled_modal_opened',
    reactionAdded: 'reaction_added',
    reactionRemoved: 'reaction_removed',
    impersonateParticipantButtonClicked: 'impersonate_participant_button_clicked',
    redirectedToChatUsingSearch: 'redirected_to_chat_using_search',
    participantEmailCopied: 'participant_email_copied',
    automatedMessagesCreated: 'automated_messages_created',
    onboardingSkipped: 'onboarding_skipped',
    onboardingStarted: 'onboarding_started',
    onboardingCompleted: 'onboarding_completed',
    chatMessageSaved: 'chat_message_saved',
    threadFollowed: 'thread_followed',
    threadUnfollowed: 'thread_unfollowed',
  },
};
