const DEFAULT_LOCALE = 'uk';

const FALLBACK_LOCALE = 'en';

const DEFAULT_SUB_DOMAIN = 'ua';

const DOMAIN_REGION = {
  uk: 'gb',
};

const REGION_DOMAIN = Object.entries(DOMAIN_REGION).reduce(
  (acc, [domain, region]) => ({
    ...acc,
    [region]: domain,
  }),
  {},
);

module.exports = {
  DEFAULT_LOCALE,
  FALLBACK_LOCALE,
  DEFAULT_SUB_DOMAIN,
  DOMAIN_REGION,
  REGION_DOMAIN,
};
