import React, {
  FC,
  memo,
  useEffect,
  useRef,
} from 'react';
import { cn } from '@/lib/classNames';
import {
  horizontalPositionModeClasses, textHorizontalClassnames,
  verticalPositionModeClasses,
  widthModeClassNames, wrapperWidthModeClassNames,
} from '@/components/ui/ToolTip/constants';
import { useClassicTooltipPosition } from './useClassicTooltipPosition';
import {
  HorizontalPositionMode,
  VerticalPositionMode,
  ToolTipWidthMode,
  TextHorizontalMode,
  ToolTipContainerWidthMode,
} from '../tooltip.typedefs';
import styles from '../ToolTip.module.scss';

interface ToolTipProps {
  text: string | JSX.Element;
  className?: string;
  horizontalPosition?: HorizontalPositionMode;
  verticalPosition?: VerticalPositionMode;
  widthMode?: ToolTipWidthMode;
  messageClassName?: string;
  topScreenOffset?: number;
  textHorizontalPosition?: TextHorizontalMode;
  isPersistant?: boolean;
  isVisible?: boolean;
  onClick?: () => void;
  isManualVerticalPositioning?: boolean;
  containerWidthMode?: ToolTipContainerWidthMode;
  isVisibleOnHover?: boolean;
  renderWithPortal?: boolean;
  dataQa?: string;
}

export const ClassicTooltip: FC<ToolTipProps> = memo(({
  children,
  text,
  className,
  horizontalPosition,
  verticalPosition = VerticalPositionMode.Top,
  widthMode = ToolTipWidthMode.Min,
  messageClassName,
  topScreenOffset,
  textHorizontalPosition = TextHorizontalMode.Center,
  isPersistant = false,
  isVisible,
  onClick = () => { /* empty */ },
  isManualVerticalPositioning,
  containerWidthMode = ToolTipContainerWidthMode.MaxContent,
  isVisibleOnHover = true,
  dataQa,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const {
    dynamicHorizontalPosition,
    dynamiclVerticalPosition,
  } = useClassicTooltipPosition({
    tooltipRef,
    horizontalPosition: horizontalPosition ?? HorizontalPositionMode.Center,
    verticalPosition,
    topScreenOffset,
  });

  const actualVerticalPositioning = isManualVerticalPositioning
    ? verticalPosition
    : dynamiclVerticalPosition;

  const actualHorizontalPositioning = horizontalPosition
    ?? dynamicHorizontalPosition;

  const classes = cn(
    className,
    styles.container,
    wrapperWidthModeClassNames[containerWidthMode],
    textHorizontalClassnames[textHorizontalPosition],
    'color-primary',
  );

  useEffect(() => {
    if (tooltipRef.current) {
      tooltipRef.current.onclick = onClick;
    }
  }, [
    tooltipRef,
    onClick,
  ]);

  return (
    <div className={classes}>
      {children}
      {isVisibleOnHover && (
        <div
          ref={tooltipRef}
          className={cn(
            styles.tooltip,
            horizontalPositionModeClasses[actualHorizontalPositioning],
            verticalPositionModeClasses[actualVerticalPositioning],
            widthModeClassNames[widthMode],
            messageClassName,
            {
              [styles.visible]: isVisible,
              [styles.notDisplayed]: !text || (!isVisible && isPersistant),
            },
          )}
          data-qa={dataQa}
        >
          {text}
        </div>
      )}
    </div>
  );
});
