import { IncomingHttpHeaders } from 'http';
import {
  ApolloClient,
  ApolloLink,
  HttpOptions,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { LogLevels } from '@mate-academy/logger';
import { GraphQLError } from 'graphql';
import getConfig from 'next/config';
import { Router } from '@/middleware/i18n';
import { LOGIN_NOT_AUTHORIZED } from '@/lib/constants/errors';
import { getApiLink } from '@/lib/helpers/getApiLink';
import { setInitialState } from '@/lib/helpers/setInitialState';
import { resolvers } from '@/controllers/apollo/apollo.resolvers';
import { schemas } from '@/controllers/apollo/apollo.schemas';
import { isBrowser } from '@/lib/helpers/isBrowser';
import { EventTypePolicies } from '@/controllers/apollo/apollo.typePolicies/Event.typePolicies';
import { QueryTypePolicies } from '@/controllers/apollo/apollo.typePolicies/Query.typePolicies';
import { getWebSocketLink } from '@/lib/helpers/getWebSocketLink';
import { ApolloInitialState, ReadyApolloClient } from '@/controllers/apollo/apollo.typedefs';
import { PageContext } from '@/controllers/page/page.typedefs';
import { errorHandler } from '@/core/ErrorHandler';
import { possibleTypes } from '@/controllers/apollo/apollo.constants';
import { showMessage } from '@/hooks/useFlashMessage';
import { MESSAGES } from '@/lib/constants/messages';
import { AppEnvironments } from '@/lib/constants/general';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

interface CreateApolloClient {
  (headers?: IncomingHttpHeaders): ReadyApolloClient;
}

const createApolloClient: CreateApolloClient = (headers) => {
  const errorLink = onError((mainError) => {
    const {
      graphQLErrors,
      networkError,
      operation,
    } = mainError;

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        if (errorHandler.shouldIgnoreException(error)) {
          return;
        }

        errorHandler.withSentryScope((scope) => {
          scope.setFingerprint([error.message, operation.operationName]);

          scope.setExtra('query-name', operation.operationName);
          scope.setExtra('query-extensions', JSON.stringify(error.extensions, null, 2));
          scope.setExtra('query', operation.query.loc?.source.body);
          scope.setExtra('variables', operation.variables);

          const errorPath = error.path?.join(' > ');

          if (errorPath) {
            scope.setExtra('query-path', errorPath);
          }

          // Error handler should capture an Error instance
          const exception = new GraphQLError(error.message);

          errorHandler.captureException(exception, {
            logLevel: LogLevels.Warning,
            logMessage: '[GraphQL error]',
            fields: {
              path: errorPath,
            },
          });
        });

        if (
          // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
          typeof window !== 'undefined'
          && error.message.includes(LOGIN_NOT_AUTHORIZED)
        ) {
          Router.replace('/sign-in');
        }
      });
    }

    if (
      networkError
      // NOTE: we do want to show a message when the user is offline or something similar
      && !networkError.message.includes('Response not successful')
    ) {
      showMessage(MESSAGES.general.networkError);
    }
  });

  const uploadLink = createUploadLink({
    uri: getApiLink(),
    credentials: 'include',
    headers: headers as HttpOptions['headers'],
  });

  const apiLink = ApolloLink.from([
    errorLink,
    uploadLink,
  ]);

  const wsLink = isBrowser
    ? new WebSocketLink({
      uri: getWebSocketLink('ws'),
      options: {
        reconnect: true,
        lazy: true,
        inactivityTimeout: 10000,
      },
    })
    : null;

  const link = isBrowser && wsLink
    ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);

        return definition.kind === 'OperationDefinition'
          && definition.operation === 'subscription';
      },
      wsLink,
      apiLink,
    )
    : apiLink;

  const cache = new InMemoryCache({
    typePolicies: {
      ...EventTypePolicies,
      ...QueryTypePolicies,
    },
    possibleTypes,
  });

  // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
  if (typeof window === 'undefined') {
    setInitialState(cache);
  }

  const { publicRuntimeConfig: config = {} } = getConfig() || {};
  const shouldEnableDevTools = config.APP_ENV === AppEnvironments.Local
    || config.APP_ENV === AppEnvironments.Development;

  const client = new ApolloClient({
    resolvers,
    typeDefs: schemas,
    // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
    ssrMode: typeof window === 'undefined',
    link,
    cache,
    connectToDevTools: shouldEnableDevTools,
  });

  client.onResetStore(async () => setInitialState(cache));
  client.onClearStore(async () => setInitialState(cache));

  return client;
};

export interface InitApollo {
  (props?: {
    initialState?: ApolloInitialState;
    headers?: IncomingHttpHeaders;
  }): ReadyApolloClient;
}

export const initApollo: InitApollo = ({ initialState, headers } = {}) => {
  const client = apolloClient ?? createApolloClient(headers);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    client.cache.restore({
      ...existingCache,
      ...initialState,
    });
  }

  // For SSG and SSR always create a new Apollo Client
  // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
  if (typeof window === 'undefined') {
    return client;
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = client;
  }

  return apolloClient;
};

export interface InitApolloInContext {
  (props: {
    ctx: PageContext;
    headers: IncomingHttpHeaders;
  }): ReadyApolloClient;
}

export const initApolloInContext: InitApolloInContext = ({ ctx, headers }) => {
  const client = ctx.apolloClient ?? initApollo({
    initialState: ctx.apolloState, headers,
  });

  Object.assign(client, { toJSON: () => null });
  Object.assign(ctx, { apolloClient: client });

  return client;
};
