import React, { FC, useMemo } from 'react';
import { DefaultSeo } from 'next-seo';
import { useRouter } from '@/middleware/i18n';
import {
  useSubDomainContext,
} from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import getClientLink from '@/lib/helpers/getClientLink';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { removeQueryParams } from '@/controllers/analytics/analytics.utils/removeQueryParams';

export const DefaultSeoTags: FC = () => {
  const { asPath } = useRouter();
  const { subDomain, language } = useSubDomainContext();
  const features = useFeatures();

  const currentPathWithoutParams = useMemo(() => {
    const path = removeQueryParams(asPath);

    if (path === '/') {
      return '';
    }

    return path;
  }, [asPath]);

  const currentUrl = getClientLink(
    currentPathWithoutParams,
    { domain: subDomain, language },
  );

  return (
    <DefaultSeo
      canonical={currentUrl}
      titleTemplate="%s | Mate academy"
      dangerouslySetAllPagesToNoIndex={features.isEnabled(
        Features.DefaultNoindex,
      )}
      openGraph={{
        type: 'website',
        locale: language,
        url: currentUrl,
        site_name: 'Mate academy',
        images: [
          {
            url: '/static/landing-link-preview.jpg',
          },
          {
            url: '/static/logo.svg',
          },
        ],
      }}
    />
  );
};
