export enum PlatformTheme {
  Light = 'light',
  Dark = 'dark',
}

export enum PlatformThemePreference {
  Light = 'light',
  Dark = 'dark',
  System = 'system',
}
