import React, {
  createContext, useCallback, useEffect, useState,
} from 'react';
import { PlatformTheme, PlatformThemePreference } from '@/components/common/ThemeContext/typedefs';
import { MEDIA_PREFERS_DARK_SCHEME, THEME_ATTRIBUTE } from '@/components/common/ThemeContext/constants';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { useTrackSystemTheme } from '@/components/common/ThemeContext/useTrackSystemTheme';
import { emptyFunction } from '@/lib/helpers/functional';
import { analyticsSDK } from '@/controllers/analytics';
import { errorHandler } from '@/core/ErrorHandler';

interface ContextProps {
  theme: PlatformTheme;
  setThemePreference: (themePreference: PlatformThemePreference) => void;
  themePreference: PlatformThemePreference;
}

export const PlatformThemeContext = createContext<ContextProps>({
  theme: PlatformTheme.Light,
  setThemePreference: emptyFunction,
  themePreference: PlatformThemePreference.Light,
});

interface Props {
  children?: React.ReactNode;
}

export const setThemeAttribute = (theme: PlatformTheme) => {
  document.documentElement.setAttribute(THEME_ATTRIBUTE, theme);
};

export const PlatformThemeProvider: React.FC<Props> = ({ children }) => {
  const [platformThemePreference, setPlatformThemePreference] = useLocalStorage(
    LOCAL_STORAGE_KEYS.platformThemePreference,
    PlatformThemePreference.Light,
  );
  const [
    platformTheme,
    setPlatformTheme,
  ] = useState(PlatformTheme.Light);

  const setTheme = useCallback(
    (theme: PlatformTheme) => {
      setPlatformTheme(theme);
      setThemeAttribute(theme);

      try {
        window.localStorage
          .setItem(LOCAL_STORAGE_KEYS.lastUsedPlatformTheme, theme);
      } catch (error) {
        errorHandler.captureException(error, {
          logMessage: `[Theme context]: cant write lastUsedPlatformTheme to storage`,
          fields: {
            theme,
          },
        });
      }
    },
    [],
  );

  const getThemeByPreference = useCallback(
    (themePreference: PlatformThemePreference) => {
      switch (themePreference) {
        case PlatformThemePreference.Light:
          return PlatformTheme.Light;

        case PlatformThemePreference.Dark:
          return PlatformTheme.Dark;

        case PlatformThemePreference.System: {
          const {
            matches: prefersDarkTheme,
          } = window.matchMedia(MEDIA_PREFERS_DARK_SCHEME);

          return prefersDarkTheme
            ? PlatformTheme.Dark
            : PlatformTheme.Light;
        }

        default:
          return PlatformTheme.Light;
      }
    },
    [],
  );

  const changeThemePreference = useCallback(
    (themePreference: PlatformThemePreference) => {
      setPlatformThemePreference(themePreference);

      const theme = getThemeByPreference(themePreference);

      setTheme(theme);

      analyticsSDK.platformThemePreference
        .sendChangedEvent({ chosenTheme: themePreference });
    },
    [getThemeByPreference, setPlatformThemePreference, setTheme],
  );

  useTrackSystemTheme({
    condition: platformThemePreference === PlatformThemePreference.System,
    setTheme,
  });

  useEffect(() => {
    const theme = getThemeByPreference(platformThemePreference);

    setTheme(theme);
  }, [getThemeByPreference, platformThemePreference, setTheme]);

  return (
    <PlatformThemeContext.Provider
      value={{
        theme: platformTheme,
        setThemePreference: changeThemePreference,
        themePreference: platformThemePreference,
      }}
    >
      {children}
    </PlatformThemeContext.Provider>
  );
};

export const usePlatformTheme = () => (
  React.useContext(PlatformThemeContext)
);
