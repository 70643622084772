import React, { ComponentType, useEffect } from 'react';
import { AppProps } from 'next/app';
import { makeVar } from '@apollo/client';
import { PageProps } from '@/controllers/page/page.typedefs';
import { setDisplayName } from '@/controllers/page/setDisplayName';
import { DEFAULT_SUB_DOMAIN, SUB_DOMAINS } from '@/middleware/i18n/i18n.config';
import { i18nParseLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import { AppGetInitialProps, MateAppProps } from '../../../pages/_app';
import { SubDomainProvider } from './subDomain.context';

export const subDomainVar = makeVar<string>(
  DEFAULT_SUB_DOMAIN,
);

interface ComponentProps {
  /* eslint-disable react/require-default-props */
  subDomain?: SUB_DOMAINS;
  /* eslint-enable react/require-default-props */
}

interface WithSubDomainApp {
  (
    App: ComponentType<MateAppProps> & { getInitialProps?: AppGetInitialProps }
  ): ComponentType<MateAppProps>;
}

export const withSubDomainApp: WithSubDomainApp = (App) => {
  const WithSubDomainApp = (props: AppProps<PageProps> & ComponentProps) => {
    const subDomain = props.subDomain ?? DEFAULT_SUB_DOMAIN;

    useEffect(() => {
      subDomainVar(subDomain);
    }, [subDomain]);

    return (
      <SubDomainProvider value={{ subDomain }}>
        <App {...props} />
      </SubDomainProvider>
    );
  };

  const getInitialProps: AppGetInitialProps = async (ctx) => {
    const { ctx: { locale } } = ctx;

    let initialProps = {};

    const subDomain = i18nParseLanguageSubpath(locale).domain;

    Object.assign(ctx.ctx, { subDomain });

    if (App.getInitialProps) {
      initialProps = await App.getInitialProps(ctx);
    }

    return {
      ...initialProps,
      subDomain,
    };
  };

  WithSubDomainApp.getInitialProps = getInitialProps;

  setDisplayName(App, WithSubDomainApp, 'withSubDomainApp');

  return WithSubDomainApp;
};
