import { TFunction } from 'next-i18next';
import { ApolloCache, ApolloError, NormalizedCacheObject } from '@apollo/client';
import {
  CourseAssignmentTestConfigRule,
  CreateTaskValues,
  FullLanguageName,
  TaskErrorCodes,
  TopicContent,
} from '@/components/platform/LmsEditor/LmsEditor.typedefs';
import {
  Course, CourseModulesDocument, CourseModulesQuery,
  CourseTopicContentType,
  CreateTestTaskPreviewMutation,
  TopicTheoryFile,
} from '@/controllers/graphql/generated';
import {
  ExistingTaskFormFields,
} from '@/components/platform/LmsEditor/LmsEditorTaskForm/ExistingTaskForm/ExistingTask.typedefs';
import {
  complexityOptions,
  FULL_LANGUAGE_NAME_TO_LANGUAGE_NAME,
  isOptionalOptions,
  TaskFormsErrorMessageCodes,
} from '@/components/platform/LmsEditor/LmsEditor.constants';
import { CreateTestTaskPreviewFields } from '@/components/platform/LmsEditor/TestTaskPreview/typedefs';
import { BaseFlashMessage } from '@/controllers/platform/flashMessages/flashMessages.typedefs';
import { I18N_CODES } from '@/lib/constants/general';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { last } from '@/lib/helpers/array';

interface ExistingTaskValuesBase {
  id: number;
  name?: string | null;
  slug: string;
}

type Options<T, U> = {
  complexity?: number;
  testTask?: T | null;
  courseTopic?: U | null;
  isOptional?: boolean;
};

export const getExistingTaskFormInitialValues = <
  T extends ExistingTaskValuesBase,
  U extends ExistingTaskValuesBase
  >({
    complexity,
    testTask,
    courseTopic,
    isOptional,
  }: Options<T, U>): Partial<ExistingTaskFormFields> => {
  const values: Partial<ExistingTaskFormFields> = {
    complexity: complexityOptions.find(
      ({ value }) => value === `${complexity}`,
    ),
  };

  if (testTask) {
    Object.assign(values, {
      taskTemplateName: {
        value: testTask.id,
        label: `${testTask.name} (${testTask.slug})`,
      },
    });
  }

  if (courseTopic) {
    Object.assign(values, {
      topicSlug: {
        value: `${courseTopic.id}`,
        label: `${courseTopic.slug}`,
      },
    });
  }

  if (isOptional !== undefined) {
    const option = isOptionalOptions.find(({ value }) => value === isOptional);

    Object.assign(values, {
      isOptional: option,
    });
  }

  return values;
};

export const getFileNameAndFolderName = (filePath: string): {
  folderName?: string;
  fileNameWithExtension?: string;
  fileNameWithoutExtension?: string;
} => {
  const pathItems = filePath.split('/').filter((pathItem) => Boolean(pathItem));
  const folderName = pathItems[pathItems.length - 2];
  const fileNameWithExtension = last(pathItems);
  const fileNameWithoutExtension = fileNameWithExtension?.split('.')[0];

  return { folderName, fileNameWithExtension, fileNameWithoutExtension };
};

export const prepareFilesForCommit = (
  prevState: TopicContent,
  newState: TopicContent,
  fileName: string,
  courseTopicTheoryFileId: number | undefined,
): TopicTheoryFile[] => (
  Object.values(FullLanguageName).reduce((acc: TopicTheoryFile[], language) => {
    if (prevState[language] !== newState[language]) {
      acc.push({
        path: `${fileName}.${FULL_LANGUAGE_NAME_TO_LANGUAGE_NAME[language]}.md`,
        content: newState[language],
        lang: FULL_LANGUAGE_NAME_TO_LANGUAGE_NAME[language],
        type: CourseTopicContentType.Theory,
        courseTopicTheoryFileId,
      });
    }

    return acc;
  }, [])
);

export const getGraphqlFieldArgs = <T = any>(
  storeFieldName: string,
  fieldName: string,
) => {
  const args = storeFieldName.replace(fieldName, '').slice(1, -1);

  return JSON.parse(args) as T;
};

export const getCreateTaskValues = (
  formData: ExistingTaskFormFields,
  professionSlug: string,
): CreateTaskValues => {
  const {
    taskTemplateName,
    complexity,
    topicSlug,
    isOptional,
    courseModuleId,
  } = formData;

  return {
    testTaskId: Number(taskTemplateName.value),
    complexity: Number(complexity.value),
    courseTopicId: topicSlug?.value
      ? Number(topicSlug.value)
      : undefined,
    professionSlug,
    isOptional: isOptional?.value,
    courseModuleId: courseModuleId?.value
      ? courseModuleId.value
      : undefined,
  };
};

export const getDifferentValues = <T extends Record<string, any>>(
  initialValues: T | undefined,
  currentValues: T,
) => {
  const differentValues: Partial<T> = {};

  if (!initialValues) {
    return differentValues;
  }

  const currentValuesKeys = Object.keys(currentValues);

  currentValuesKeys.forEach((key) => {
    const initialValue = initialValues[key as keyof T];
    const currentValue = currentValues[key as keyof T];

    if (initialValue !== currentValue) {
      differentValues[key as keyof T] = currentValue;
    }
  });

  return differentValues;
};

const areObjectsEqualShallow = <T extends Record<string, unknown>>(
  firstObject: T,
  secondObject: T,
): boolean => {
  const keys = Object.keys(firstObject);

  if (keys.length !== Object.keys(secondObject).length) {
    return false;
  }

  return keys.every((key) => (
    firstObject[key] === secondObject[key]
  ));
};

export const areObjectArraysEqualShallow = <T extends Record<string, unknown>>(
  firstArray: T[],
  secondArray: T[],
): boolean => {
  if (firstArray.length !== secondArray.length) {
    return false;
  }

  return firstArray.every((value, index) => (
    areObjectsEqualShallow(value, secondArray[index] ?? {})
  ));
};

export const prepareTestTaskPreviewCacheRecord = (
  formData: CreateTestTaskPreviewFields,
  createTestTaskPreviewData: CreateTestTaskPreviewMutation,
) => {
  const { taskTemplateName } = formData;
  const { name, flow } = taskTemplateName;

  const { createTestTaskPreview } = createTestTaskPreviewData;
  const { id, testTaskId } = createTestTaskPreview;

  return {
    __typename: 'TestTasksPreview',
    id,
    testTaskId,
    testTask: {
      __typename: 'TestTask',
      name,
      flow,
    },
  };
};

export const generateCourseAssignmentTestConfigRule = () => ({
  complexity: null,
  count: null,
});

export const getCourseAssignmentTestConfigRules = (
  rules: CourseAssignmentTestConfigRule[] | null,
) => (
  rules?.map(({ complexity, count }) => ({
    complexity: Number(complexity),
    count: Number(count),
  })) || []
);

export const getCourseAssignmentTestConfig = (
  firstTry: CourseAssignmentTestConfigRule[] | null,
  retry: CourseAssignmentTestConfigRule[] | null,
) => ({
  firstTry: getCourseAssignmentTestConfigRules(firstTry),
  retry: getCourseAssignmentTestConfigRules(retry),
});

export const showCreateTaskErrorMessage = (
  error: ApolloError,
  showMessage: (message: BaseFlashMessage) => void,
  t: TFunction,
) => {
  const errorMessageCode: string | undefined = TaskFormsErrorMessageCodes[
    error.message as TaskErrorCodes
  ];

  const errorMessage = errorMessageCode
    ? t(`${I18N_CODES.lmsEditor}:${errorMessageCode}`)
    : `${error.message}`;

  showMessage({
    type: MESSAGE_TYPES.error,
    heading: t(`${I18N_CODES.lmsEditor}:task_not_added_headings`),
    text: t(`${I18N_CODES.lmsEditor}:task_not_added_message`, {
      message: errorMessage,
    }),
  });
};

export const showUpdateTaskErrorMessage = (
  error: ApolloError,
  showMessage: (message: BaseFlashMessage) => void,
  t: TFunction,
) => {
  const errorMessageCode: string | undefined = TaskFormsErrorMessageCodes[
    error.message as TaskErrorCodes
  ];

  const errorMessage = errorMessageCode
    ? t(`${I18N_CODES.lmsEditor}:${errorMessageCode}`)
    : `Task is not updated: ${error.message}`;

  showMessage({
    type: MESSAGE_TYPES.error,
    heading: t(`${I18N_CODES.lmsEditor}:task_not_updated_headings`),
    text: t(`${I18N_CODES.error}:task_not_updated_message`, {
      message: errorMessage,
    }),
  });
};

export const updateModulesListForProfession = (
  cache: ApolloCache<NormalizedCacheObject>,
  courseSlug: string,
  newModule: Partial<Course>,
) => {
  const existingCache = cache.readQuery<
    CourseModulesQuery
  >({
    query: CourseModulesDocument,
    variables: {
      slug: courseSlug,
    },
  });

  if (existingCache) {
    cache.writeQuery({
      query: CourseModulesDocument,
      variables: {
        slug: courseSlug,
      },
      data: {
        ...existingCache,
        profession: {
          ...existingCache.profession,
          modules: [
            ...existingCache.profession.modules,
            { ...newModule, position: existingCache.profession.modules.length },
          ],
        },
      },
    });
  }
};
