import { isBrowser } from '@/lib/helpers/isBrowser';
import {
  useChatMessageReadSubscription,
} from '@/components/platform/Chat/graphql/generated/chatMessageRead.subscription.generated';
import { resetUnreadChatMessagesCount, updateParticipantLastActionTime } from '@/components/platform/Chat/store.helpers';
import { MessageType } from '@/components/platform/Chat/chat.typedefs';
import { MentionCacheHelper } from '@/components/platform/Chat/cache.helpers/mention.helper';

export const useChatMessageRead = ({ skip }: { skip: boolean }) => {
  useChatMessageReadSubscription({
    skip: !isBrowser || skip,
    onData: ({
      client,
      data: { data },
    }) => {
      const {
        message,
        participantId,
        hasUnreadChatMessages = false,
        unreadChatMentionsCount = 0,
      } = data?.chatMessageRead || {};

      if (!message || !participantId) {
        return;
      }

      switch (message.__typename) {
        case MessageType.Thread:
        case MessageType.OpenQuestion:
        case MessageType.Poll:
        case MessageType.SystemMessage: {
          const { chatId, createdAt } = message;
          const store = client.cache;

          updateParticipantLastActionTime({
            store,
            participantId,
            lastActionTime: createdAt,
            chatId,
          });

          resetUnreadChatMessagesCount({
            hasUnreadChatMessages,
            chatId,
            store,
          });

          const mentionCacheHelper = new MentionCacheHelper(store);

          mentionCacheHelper.setGlobalChatMentionsCount(
            unreadChatMentionsCount,
          );

          mentionCacheHelper.setChatUnreadMentionsCount(
            chatId,
            0,
          );

          mentionCacheHelper.resetChatMentionsCounter(chatId);

          break;
        }

        default:
          break;
      }
    },
  });
};
