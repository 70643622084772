import React from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from './IconLogoSquare.module.scss';

const textColor = 'var(--c-logo-text, white)';

export const IconLogoSquareDeprecated: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    className={cn(className, styles.logoIcon)}
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M26.8713 24.1327C26.8713 25.5357 27.813 26.415 29.1125 26.415C30.4119 26.415 31.3536 25.5357 31.3536 24.1327C31.3536 22.7296 30.4119 21.8503 29.1125 21.8503C27.813 21.8503 26.8713 22.7296 26.8713 24.1327Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5714 0C39.7787 0 44 4.22132 44 9.42857V34.5714C44 39.7787 39.7787 44 34.5714 44H9.42857C4.22131 44 0 39.7787 0 34.5714V9.42857C0 4.22132 4.22131 0 9.42857 0H34.5714ZM19.9913 15.7143H23.0046V28.8095H19.9913V21.1956L16.5825 26.7517H16.2435L12.8347 21.1956V28.8095H9.82143V15.7143H12.8347L16.413 21.5323L19.9913 15.7143ZM31.3536 20.335V19.4558H34.1786V28.8095H31.3536V27.9303C30.7321 28.6412 29.8093 29.0714 28.5475 29.0714C26.0803 29.0714 24.0463 26.9201 24.0463 24.1327C24.0463 21.3452 26.0803 19.1939 28.5475 19.1939C29.8093 19.1939 30.7321 19.6241 31.3536 20.335Z"
      fill="currentColor"
    />
    <path
      d="M23.0052 15.7142H19.9919L16.4136 21.5322L12.8353 15.7142H9.82202V28.8094H12.8353V21.1955L16.2441 26.7516H16.5831L19.9919 21.1955V28.8094H23.0052V15.7142Z"
      fill={textColor}
    />
    <path
      d="M31.3542 19.4557V20.3349C30.7327 19.624 29.8099 19.1938 28.5481 19.1938C26.0809 19.1938 24.0469 21.3451 24.0469 24.1325C24.0469 26.92 26.0809 29.0713 28.5481 29.0713C29.8099 29.0713 30.7327 28.641 31.3542 27.9302V28.8094H34.1792V19.4557H31.3542ZM29.113 26.4149C27.8136 26.4149 26.8719 25.5356 26.8719 24.1325C26.8719 22.7295 27.8136 21.8502 29.113 21.8502C30.4125 21.8502 31.3542 22.7295 31.3542 24.1325C31.3542 25.5356 30.4125 26.4149 29.113 26.4149Z"
      fill={textColor}
    />
  </BaseIcon>
);
