import { useCallback, useEffect } from 'react';
import { PlatformTheme } from '@/components/common/ThemeContext/typedefs';
import { MEDIA_PREFERS_DARK_SCHEME } from '@/components/common/ThemeContext/constants';
import { useListenMediaChange } from '@/hooks/useListenMediaChange';

type Options = {
  condition: boolean;
  setTheme: (theme: PlatformTheme) => void;
};

export const useTrackSystemTheme = ({ condition, setTheme }: Options) => {
  const handleColorSchemeChange = useCallback((darkThemeMatches: boolean) => {
    setTheme(darkThemeMatches
      ? PlatformTheme.Dark
      : PlatformTheme.Light);
  }, [setTheme]);

  useEffect(() => {
    if (!condition) {
      return;
    }

    const prefersDarkThemeMediaQuery = window.matchMedia(
      MEDIA_PREFERS_DARK_SCHEME,
    );

    if (prefersDarkThemeMediaQuery.matches) {
      // set system theme initially
      setTheme(PlatformTheme.Dark);
    }
  }, [condition, setTheme]);

  useListenMediaChange({
    media: MEDIA_PREFERS_DARK_SCHEME,
    condition,
    callback: handleColorSchemeChange,
  });
};
