import getConfig from 'next/config';
import { DEFAULT_SUB_DOMAIN, FALLBACK_LOCALE, DEFAULT_LOCALE } from '@/middleware/i18n/i18n.config';
import { i18nCreateLanguageSubpath, i18nReplaceLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import { getRootClientLink } from '@/lib/helpers/getRootClientLink';

const getClientLink = (
  path: string,
  options: { domain?: string; language?: string } = {},
) => {
  const {
    domain = DEFAULT_SUB_DOMAIN,
    language = FALLBACK_LOCALE,
  } = options || {};

  const { publicRuntimeConfig = {} } = getConfig() || {};
  const { DYNAMIC_I18N_CONFIG } = publicRuntimeConfig;

  const domainLocales = DYNAMIC_I18N_CONFIG?.domainLocaleMap?.[domain] ?? [];

  const domainLanguage = domainLocales.includes(language)
    ? language
    : domainLocales[0] ?? FALLBACK_LOCALE;

  const languageSubpath = i18nCreateLanguageSubpath({
    language: domainLanguage,
    domain,
  });

  const normalizedSubpath = languageSubpath === DEFAULT_LOCALE
    ? null
    : languageSubpath;

  return i18nReplaceLanguageSubpath({
    url: getRootClientLink(path),
    language: normalizedSubpath,
    supportedLanguages: DYNAMIC_I18N_CONFIG?.languages ?? [],
  });
};

export default getClientLink;
