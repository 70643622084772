import getConfig from 'next/config';
import { isBrowser } from '@/lib/helpers/isBrowser';

export const getWebSocketLink = (path = '', host?: string) => {
  const { publicRuntimeConfig = {} } = getConfig() || {};
  const {
    API_SSL, API_PORT, API_HOST, API_HOST_PUBLIC,
  } = publicRuntimeConfig;
  const config = {
    ssl: API_SSL === 'true',
    port: API_PORT,
    hostname: host || (
      isBrowser
        ? API_HOST_PUBLIC
        : API_HOST
    ),
  };
  const {
    ssl, hostname, port,
  } = config;
  const protocol = `ws${(ssl && isBrowser)
    ? 's'
    : ''}`;
  const apiPort = (ssl && isBrowser)
    ? 443
    : port;

  let link = `${protocol}://${hostname}:${apiPort}`;

  if (path) {
    link += `/${path}`;
  }

  return link;
};
