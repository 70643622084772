import React, { FC, memo } from 'react';
import { cn } from '@/lib';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { IconLogoSquareDeprecated } from '@/components/ui/icons/IconLogoSquareDeprecated';
import { IconLogoSquare } from '@/components/ui/icons/IconLogoSquare';
import styles from './MateLogo.module.scss';

type Props = {
  className?: string;
  withHoverEffect?: boolean;
};

export const MateLogoSquare: FC<Props> = memo(({
  className,
  withHoverEffect = false,
}) => {
  const features = useFeatures();
  const isLogoRedesignEnabled = features.isEnabled(
    Features.MateLogoRedesign,
  );

  return isLogoRedesignEnabled
    ? (
      <IconLogoSquare
        className={cn(className, {
          [styles.withHoverEffect]: withHoverEffect,
        })}
      />
    )
    : (
      <IconLogoSquareDeprecated className={className} />
    );
});
